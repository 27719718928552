import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState, PagedData } from "../Utils";

export type FinishedProduct = {
    id: number;
    date: string;
    project_id: number;
    from_id: number;
    to_id: number;
    received_by: string;
    approved_by: string;
    checked_by: string;
    user_id: number;
    finished_product_project: Store;
    finished_product_from: Store;
    finished_product_to: Store;
    finished_product_prepared_by: User;
    finished_product_checked_by: User;
    finished_product_approved_by: User;
    finished_product_received_by: User;
    finished_product_remarks: FinishedProductRemark[];
    finished_product_statuses: FinishedProductStatus[];
    status: FinishedProductStatus[];
    finished_product_items: FinishedProductItem[];
    updatedAt: string;
};

export type FinishedProductReport = {
    id: number;
    code: string;
    date: string;
    project_id: number;
    from_id: number;
    to_id: number;
    description: string;
    quantity: number;
    unit: string;
    unit_price: number;
    total: number;
    remark: string;
    prepared_by: string;
    approved_by: string;
    checked_by: string;
    received_by: string;
};

export type FinishedProductRemark = {
    time: string;
    remark: string;
    user_id: number;
    document?: Document;
};

export type FinishedProductStatus = {
    finished_product_id: number;
    type: "View" | "Check" | "Approve";
    status: number;
    assigned_by: number;
    user_id: number;
    id: number;
};

export type FinishedProductItem = {
    key: number;
    id?: number;
    material_id?: number;
    finished_product_id?: number;
    quantity: number;
    unit: string;
    unit_price: number;
    remark: string;
    material?: Material;
};

export type FinishedProductStateTypes = {
    fetchAll: ApiCallState<FinishedProduct[]>;
    fetchOne: ApiCallState<FinishedProduct | {}>;
    fetchPaged: ApiCallState<PagedData<FinishedProduct[]>>;
    fetchReport: ApiCallState<FinishedProductReport[]>;
}

export const FinishedProductActionTypes = {
    FETCH_ALL_FINISHED_PRODUCT: "FETCH_ALL_FINISHED_PRODUCT",
    FETCH_ALL_FINISHED_PRODUCT_SUCCESS: "FETCH_ALL_FINISHED_PRODUCT_SUCCESS",
    FETCH_ALL_FINISHED_PRODUCT_FAILURE: "FETCH_ALL_FINISHED_PRODUCT_FAILURE",
    FETCH_ALL_FINISHED_PRODUCT_RESET: "FETCH_ALL_FINISHED_PRODUCT_RESET",

    FETCH_ONE_FINISHED_PRODUCT: "FETCH_ONE_FINISHED_PRODUCT",
    FETCH_ONE_FINISHED_PRODUCT_SUCCESS: "FETCH_ONE_FINISHED_PRODUCT_SUCCESS",
    FETCH_ONE_FINISHED_PRODUCT_FAILURE: "FETCH_ONE_FINISHED_PRODUCT_FAILURE",
    FETCH_ONE_FINISHED_PRODUCT_RESET: "FETCH_ONE_FINISHED_PRODUCT_RESET",

    FETCH_PAGED_FINISHED_PRODUCT: "FETCH_PAGED_FINISHED_PRODUCT",
    FETCH_PAGED_FINISHED_PRODUCT_SUCCESS: "FETCH_PAGED_FINISHED_PRODUCT_SUCCESS",
    FETCH_PAGED_FINISHED_PRODUCT_FAILURE: "FETCH_PAGED_FINISHED_PRODUCT_FAILURE",
    FETCH_PAGED_FINISHED_PRODUCT_RESET: "FETCH_PAGED_FINISHED_PRODUCT_RESET",

    FETCH_REPORT_FINISHED_PRODUCT: "FETCH_REPORT_FINISHED_PRODUCT",
    FETCH_REPORT_FINISHED_PRODUCT_SUCCESS: "FETCH_REPORT_FINISHED_PRODUCT_SUCCESS",
    FETCH_REPORT_FINISHED_PRODUCT_FAILURE: "FETCH_REPORT_FINISHED_PRODUCT_FAILURE",
    FETCH_REPORT_FINISHED_PRODUCT_RESET: "FETCH_REPORT_FINISHED_PRODUCT_RESET",
};
