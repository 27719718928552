import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { MaterialRequisitionActionTypes } from "./MaterialRequisition.type";
import { formatQuery } from "../Utils";

export function* fetchAllMaterialRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-requisition?${formatQuery(action)}`
    );
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPagedMaterialRequisition(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-requisition/paged?${formatQuery(action)}`
    );
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_PAGED_MATERIAL_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_PAGED_MATERIAL_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchListMaterialRequisition(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-requisition/list?${formatQuery(action)}`
    );
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_LIST_MATERIAL_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_LIST_MATERIAL_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMaterialRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/material-requisition/${action.payload}`
    );
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPreviousMaterialRequisitionItems(action: any): any {
  try {
    const query = formatQuery(action);

    const response = yield axios.get(
      `${API_BASE_URI}/material-requisition/previous-item-report?${query}`
    );
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMaterialRequisitions() {
  yield takeLatest(
    MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION,
    fetchAllMaterialRequisitions
  );
}

export function* watcherFetchPagedMaterialRequisitions() {
  yield takeLatest(
    MaterialRequisitionActionTypes.FETCH_PAGED_MATERIAL_REQUISITION,
    fetchPagedMaterialRequisition
  );
}

export function* watcherFetchListMaterialRequisitions() {
  yield takeLatest(
    MaterialRequisitionActionTypes.FETCH_LIST_MATERIAL_REQUISITION,
    fetchListMaterialRequisition
  );
}

export function* watcherFetchOneMaterialRequisitions() {
  yield takeLatest(
    MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION,
    fetchOneMaterialRequisitions
  );
}

export function* watcherFetchPreviousMaterialRequisitionItems() {
  yield takeLatest(
    MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST,
    fetchPreviousMaterialRequisitionItems
  );
}
