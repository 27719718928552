import {
  MaterialRequisitionStateTypes,
  MaterialRequisitionActionTypes,
} from "./MaterialRequisition.type";
import { InitPagedData, resetApiCallState } from "../Utils";

const INITIAL_STATE: MaterialRequisitionStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPreviousItemList: resetApiCallState(null),
  fetchPaged: resetApiCallState(InitPagedData),
  fetchList: resetApiCallState([]),
};

const MaterialRequisitionReducer = (
  state: MaterialRequisitionStateTypes = INITIAL_STATE,
  action: any
): MaterialRequisitionStateTypes => {
  switch (action.type) {
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ALL_MATERIAL_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_ONE_MATERIAL_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    // -------------------------

    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST:
      return {
        ...state,
        fetchPreviousItemList: {
          error: null,
          payload: null,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_RESET:
      return {
        ...state,
        fetchPreviousItemList: resetApiCallState(null),
      };
    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_FAILURE:
      return {
        ...state,
        fetchPreviousItemList: {
          payload: null,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_MATERIAL_REQUISITION_ITEM_PREVIOUS_LIST_SUCCESS:
      return {
        ...state,
        fetchPreviousItemList: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialRequisitionActionTypes.FETCH_PAGED_MATERIAL_REQUISITION:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_PAGED_MATERIAL_REQUISITION_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case MaterialRequisitionActionTypes.FETCH_PAGED_MATERIAL_REQUISITION_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_PAGED_MATERIAL_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case MaterialRequisitionActionTypes.FETCH_LIST_MATERIAL_REQUISITION:
      return {
        ...state,
        fetchList: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_LIST_MATERIAL_REQUISITION_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case MaterialRequisitionActionTypes.FETCH_LIST_MATERIAL_REQUISITION_FAILURE:
      return {
        ...state,
        fetchList: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case MaterialRequisitionActionTypes.FETCH_LIST_MATERIAL_REQUISITION_SUCCESS:
      return {
        ...state,
        fetchList: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default MaterialRequisitionReducer;
