import { Material } from "../Material/Material.type";
import { Store } from "../Store/Store.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type ServiceRequest = {
  id: number;
  requested_by_id: number;
  requested_to_id: number;
  user_id: number;
  checked_by_id: number;
  approved_by_id: number;

  date: string;
  service: string;
  type: string;

  requested_by: Store;
  requested_to: Store;
  user: User;
  approved_by: User;
  checked_by: User;
  service_request_items: ServiceRequestItem[];
  service_request_statuses: ServiceRequestStatus[];

  readonly createdAt: Date;
  readonly updatedAt: Date;

  is_void: boolean;
};

export type ServiceRequestItem = {
  id: number;
  service_request_id: number;
  material_id: number;

  unit: string;
  quantity: number;
  remark: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  material: Material;
};

export type ServiceRequestStatus = {
  id: number;
  user_id: number;
  service_request_id: number;
  status: number;
  type: "View" | "Check" | "Approve";
  assigned_by: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type ServiceRequestStateTypes = {
  fetchAll: ApiCallState<ServiceRequest[]>;
  fetchOne: ApiCallState<ServiceRequest | {}>;
};

export const ServiceRequestActionTypes = {
  FETCH_ALL_SERVICE_REQUEST: "FETCH_ALL_SERVICE_REQUEST",
  FETCH_ALL_SERVICE_REQUEST_RESET: "FETCH_ALL_SERVICE_REQUEST_RESET",
  FETCH_ALL_SERVICE_REQUEST_FAILURE: "FETCH_ALL_SERVICE_REQUEST_FAILURE",
  FETCH_ALL_SERVICE_REQUEST_SUCCESS: "FETCH_ALL_SERVICE_REQUEST_SUCCESS",

  FETCH_ONE_SERVICE_REQUEST: "FETCH_ONE_SERVICE_REQUEST",
  FETCH_ONE_SERVICE_REQUEST_RESET: "FETCH_ONE_SERVICE_REQUEST_RESET",
  FETCH_ONE_SERVICE_REQUEST_FAILURE: "FETCH_ONE_SERVICE_REQUEST_FAILURE",
  FETCH_ONE_SERVICE_REQUEST_SUCCESS: "FETCH_ONE_SERVICE_REQUEST_SUCCESS",
};
