import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { PurchaseRequisitionActionTypes } from "./PurchaseRequisition.type";

export function* fetchAllPurchaseRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/purchase-requisition?${formatQuery(action)}`
    );
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_ALL_PURCHASE_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_ALL_PURCHASE_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchReportPurchaseRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/purchase-requisition/report?${formatQuery(action)}`
    );
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_REPORT_PURCHASE_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_REPORT_PURCHASE_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOnePurchaseRequisitions(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/purchase-requisition/${action.payload}`
    );
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_ONE_PURCHASE_REQUISITION_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_ONE_PURCHASE_REQUISITION_FAILURE,
      payload: error,
    });
  }
}

export function* fetchPurchaseRequisitionTracking(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/purchase-requisition/tracking?${formatQuery(action)}`
    );
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_PURCHASE_REQUISITION_TRACKING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: PurchaseRequisitionActionTypes.FETCH_PURCHASE_REQUISITION_TRACKING_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllPurchaseRequisitions() {
  yield takeLatest(
    PurchaseRequisitionActionTypes.FETCH_ALL_PURCHASE_REQUISITION,
    fetchAllPurchaseRequisitions
  );
}

export function* watcherFetchReportPurchaseRequisitions() {
  yield takeLatest(
    PurchaseRequisitionActionTypes.FETCH_REPORT_PURCHASE_REQUISITION,
    fetchReportPurchaseRequisitions
  );
}

export function* watcherFetchPurchaseRequisitionTracking() {
  yield takeLatest(
    PurchaseRequisitionActionTypes.FETCH_PURCHASE_REQUISITION_TRACKING,
    fetchPurchaseRequisitionTracking
  );
}

export function* watcherFetchOnePurchaseRequisitions() {
  yield takeLatest(
    PurchaseRequisitionActionTypes.FETCH_ONE_PURCHASE_REQUISITION,
    fetchOnePurchaseRequisitions
  );
}
