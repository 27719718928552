import { InitPagedData } from "./../Utils";
import { FinishedProductStateTypes, FinishedProductActionTypes } from "./FinishedProduct.type";
import { resetApiCallState } from "../Utils"; 

const INITIAL_STATE: FinishedProductStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchReport: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  fetchPaged: resetApiCallState(InitPagedData),
};

const FinishedProductReducer = (
  state: FinishedProductStateTypes = INITIAL_STATE,
  action: any
): FinishedProductStateTypes => {
  switch (action.type) {
    case FinishedProductActionTypes.FETCH_ALL_FINISHED_PRODUCT:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FinishedProductActionTypes.FETCH_ALL_FINISHED_PRODUCT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FinishedProductActionTypes.FETCH_ALL_FINISHED_PRODUCT_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FinishedProductActionTypes.FETCH_ALL_FINISHED_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };
    ///////////

    case FinishedProductActionTypes.FETCH_REPORT_FINISHED_PRODUCT:
      return {
        ...state,
        fetchReport: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case FinishedProductActionTypes.FETCH_REPORT_FINISHED_PRODUCT_RESET:
      return {
        ...state,
        fetchReport: resetApiCallState([]),
      };
    case FinishedProductActionTypes.FETCH_REPORT_FINISHED_PRODUCT_FAILURE:
      return {
        ...state,
        fetchReport: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FinishedProductActionTypes.FETCH_REPORT_FINISHED_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchReport: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    //////////
    case FinishedProductActionTypes.FETCH_PAGED_FINISHED_PRODUCT:
      return {
        ...state,
        fetchPaged: {
          error: null,
          payload: InitPagedData,
          isPending: true,
          isSuccessful: false,
        },
      };
    case FinishedProductActionTypes.FETCH_PAGED_FINISHED_PRODUCT_RESET:
      return {
        ...state,
        fetchPaged: resetApiCallState(InitPagedData),
      };
    case FinishedProductActionTypes.FETCH_PAGED_FINISHED_PRODUCT_FAILURE:
      return {
        ...state,
        fetchPaged: {
          payload: InitPagedData,
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FinishedProductActionTypes.FETCH_PAGED_FINISHED_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchPaged: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case FinishedProductActionTypes.FETCH_ONE_FINISHED_PRODUCT:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case FinishedProductActionTypes.FETCH_ONE_FINISHED_PRODUCT_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case FinishedProductActionTypes.FETCH_ONE_FINISHED_PRODUCT_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case FinishedProductActionTypes.FETCH_ONE_FINISHED_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default FinishedProductReducer;
