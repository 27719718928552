import { ProformaStateTypes, ProformaActionTypes } from "./Proforma.type";
import { resetApiCallState } from "../Utils";

const INITIAL_STATE: ProformaStateTypes = {
  fetchAll: resetApiCallState([]),
  fetchOne: resetApiCallState({}),
  trendAnalysis: resetApiCallState([]),
};

const ProformaReducer = (
  state: ProformaStateTypes = INITIAL_STATE,
  action: any
): ProformaStateTypes => {
  switch (action.type) {
    case ProformaActionTypes.FETCH_ALL_PROFORMA:
      return {
        ...state,
        fetchAll: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProformaActionTypes.FETCH_ALL_PROFORMA_RESET:
      return {
        ...state,
        fetchAll: resetApiCallState([]),
      };
    case ProformaActionTypes.FETCH_ALL_PROFORMA_FAILURE:
      return {
        ...state,
        fetchAll: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProformaActionTypes.FETCH_ALL_PROFORMA_SUCCESS:
      return {
        ...state,
        fetchAll: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProformaActionTypes.FETCH_ONE_PROFORMA:
      return {
        ...state,
        fetchOne: {
          error: null,
          payload: {},
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProformaActionTypes.FETCH_ONE_PROFORMA_RESET:
      return {
        ...state,
        fetchOne: resetApiCallState({}),
      };
    case ProformaActionTypes.FETCH_ONE_PROFORMA_FAILURE:
      return {
        ...state,
        fetchOne: {
          payload: {},
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProformaActionTypes.FETCH_ONE_PROFORMA_SUCCESS:
      return {
        ...state,
        fetchOne: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    case ProformaActionTypes.FETCH_TREND_ANALYSIS:
      return {
        ...state,
        trendAnalysis: {
          error: null,
          payload: [],
          isPending: true,
          isSuccessful: false,
        },
      };
    case ProformaActionTypes.FETCH_TREND_ANALYSIS_RESET:
      return {
        ...state,
        trendAnalysis: resetApiCallState([]),
      };
    case ProformaActionTypes.FETCH_TREND_ANALYSIS_FAILURE:
      return {
        ...state,
        trendAnalysis: {
          payload: [],
          isPending: false,
          isSuccessful: false,
          error: action.payload,
        },
      };
    case ProformaActionTypes.FETCH_TREND_ANALYSIS_SUCCESS:
      return {
        ...state,
        trendAnalysis: {
          error: null,
          isPending: false,
          isSuccessful: true,
          payload: action.payload,
        },
      };

    default:
      return state;
  }
};

export default ProformaReducer;
